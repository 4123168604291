import { Elm } from "../src/Main.elm";
// import { Elm } from "../.elm-spa/defaults/Main.elm";
// import './elmOwnEditor.js';
// import { ParticleBackgroundComponent } from './components/particleBackground.js';


// attach all web components
// customElements.define('particle-background-component', ParticleBackgroundComponent);

// our storage in the browser
import { get, set } from 'idb-keyval';

/// Try restoring the initial state from pouchdb and afterwards initialize elm with it.
function getInitializedElm() {
    return get("project").catch(function (err) {
        console.log(err);
        return undefined;
    }).then(function (result) {

        console.log("content", result);
        const windowDims = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        }
        console.log("windowDims", windowDims);
        const content = result || {};

        const allFlags = Object.assign(content, windowDims);
        var app = Elm.Main.init({ flags: allFlags });
        console.log("loaded app");
        return app;
    });
}


/// make sure updates from elm get stored to indexedDB (browser local storage)
function startElm2CacheSync(app) {
    // How to solve "I want to store not one project's app state, but switch between projects."
    // A: keyvalstore: Dict[ProjectName, ProjectState]
    //      Q: How to find out which projects are stored in localstorage?
    //      a) don't find it out, just when project is requested, show state from localstorage
    //      b) add a unique keyval "projects=[projectZero, projectB]"
    //      Q: What to load on init?
    //      a) Nothing. Add new port for loading the project state.
    // B: keyvalstore: "projects = {projectZero: State1, projectB: State2}
    // Q: How to solve "I want multiple apps stored?"
    // A: Below each project, we add all the data. Data might overlap between apps, but might not. Default is: Global State (Main.elm)

    app.ports.cacheInJs.subscribe(function (keyAndval) {
        if (!keyAndval) return;
        let splitterIndex = keyAndval.indexOf('=')
        let key = keyAndval.substring(0, splitterIndex)
        let val = keyAndval.substring(splitterIndex + 1)
        // if (!val) {
        //     // delete command.. (i.e. set to nothing)
        //     // localStorage.removeItem(storageKey);
        //     return;
        // }

        let contentObj = JSON.parse(val);
        // console.log("Got notified from elm:", contentObj);
        set(key, contentObj).catch(function (err) {
            console.log("Failed to store app state to browser storage! ", err);
        });
        // Report that the new session was stored successfully.
        // setTimeout(function () { app.ports.onStoreChange.send(val); }, 0);
    })
}

// TODO: Use a code design like this: https://gist.github.com/rupertlssmith/5c4e3be17830e40d64168f390f4aea24 and 
//                                    https://github.com/the-sett/elm-localstorage
getInitializedElm().then(function (app) {
    startElm2CacheSync(app);
});

// Whenever localStorage changes in another tab, report it if necessary.
/* window.addEventListener("storage", function (event) {
    if (event.storageArea === localStorage && event.key === storageKey) {
        app.ports.onStoreChange.send(event.newValue);
    }
    }, false); */